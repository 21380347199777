import eCommerceApi from 'ecommerce-module/api/eCommerceApi'
import { eCommerceRefreshTokenID, eCommerceTokenID } from 'ecommerce-module/core/eCommerce.selectors'
import { SET_CAMERA_PLANS } from 'vosker/src/camera-module/transmission-plan/core/cameraPlans.actions'
import messageActions from 'shared-module/message/messageActions'

export const SET_PRODUCTS = 'SET_PRODUCTS'
export const SET_PRODUCT_ITEMS = 'SET_PRODUCT_ITEMS'

const setProductItems = productItems => ({ type: SET_PRODUCT_ITEMS, productItems })

export const fetchProductItems = () => async dispatch => {
  try {
    const basketId = localStorage.getItem('basketId')
    let productItems = []

    if (basketId && basketId !== 'undefined') {
      const basketDetails = await eCommerceApi.getBasket(basketId)
      if (basketDetails && basketDetails.basketId) {
        productItems = basketDetails.productItems
      } else {
        const newBasket = await eCommerceApi.createBasket()
        if (newBasket?.basketId) {
          localStorage.setItem('basketId', newBasket.basketId)
          productItems = newBasket.productItems
        }
      }
    } else {
      const newBasket = await eCommerceApi.createBasket()
      if (newBasket?.basketId) {
        localStorage.setItem('basketId', newBasket.basketId)
        productItems = newBasket.productItems
      }
    }

    dispatch(setProductItems({ basketId, productItems }))
  } catch (err) {
    console.error(err)
  }
}

const setProducts = products => ({ type: SET_PRODUCTS, products })
const setPlans = plans => ({ type: SET_CAMERA_PLANS, plans })
const authenticateUser = () => eCommerceApi
  .authenticate()
  .then(data => {
    localStorage.setItem(eCommerceRefreshTokenID, data.refresh_token)
  })
  .catch(error => error)

const fetchProducts = () => dispatch => eCommerceApi.fetchProducts()
  .then(plans => dispatch(setProducts({ plans: plans })))
  .catch(error => error)
const fetchPlans = () => dispatch => eCommerceApi.fetchPlans()
  .then(plans => dispatch(setPlans({ plans: plans })))
  .catch(error => error)
const createBaskets = () => eCommerceApi
  .createBasket()
  .then(data => data)
  .catch(error => error)

const addItemsToBasket = (basketId, item) => async dispatch => {
  try {
    const data = await eCommerceApi.addItemToBasket(basketId, item)
    dispatch(setProductItems({ basketId: data.basketId, productItems: data.productItems }))
  } catch (error) {
    console.error(error)
  }
}

const getBasket = (basketId) => eCommerceApi
  .getBasket(basketId)
  .then(data => data)
  .catch(error => error)

const deleteBasket = (storedBasketId) => eCommerceApi
  .deleteBasket(storedBasketId)
  .then(data => data)
  .catch(error => error)

export const deleteItemFromBasket = (storedBasketId, item) => async dispatch => {
  try {
    const data = await eCommerceApi.deleteItemFromBasket(storedBasketId, item)
    dispatch(setProductItems({ basketId: data.basketId, productItems: data.productItems }))
  } catch (error) {
    console.error(error)
  }
}

const proceedToPayment = (camera, user, action, data) => async dispatch => {
  const auth = localStorage.getItem(eCommerceTokenID)
  const redirectionURL = window.location.origin + `/camera/${camera.id}`
  const countryCode = user.country === 'US' || user.country === 'CA' ? user.country.toLowerCase() : 'ca'
  const language = user.language === 'en' || user.language === 'fr' ? user.language : 'en'

  const origin = window.location.hostname === 'localhost'
    ? 'dev.spypoint'
    : window.location.hostname.replace('webapp', '').replace('.com', '').substring(1)

  const sfccOrigin = `https://cc.${origin}.com/${countryCode}/${language}/checkout/external`
  const params = `auth=${auth}&url=${redirectionURL}`

  await action(data)
    .then(() => window.location.assign(`${sfccOrigin}?${params}`))
    .catch(() => dispatch(messageActions.showError('errors.catch_all')))
}

const eCommerceActions = { authenticateUser, fetchProducts, setPlans, fetchPlans, setProducts, createBaskets, addItemsToBasket, getBasket, deleteBasket, deleteItemFromBasket, proceedToPayment, fetchProductItems }
export default eCommerceActions
